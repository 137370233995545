// Init
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { analytics } from "../firebase";
import queryString from "query-string";

// utils
import Search from "../components/Search";

// Pagination
import PublicPagination from "../components/PublicPagination.jsx";
import Pagination from "../components/Pagination.jsx";

// Compnent
function Public({ loggedIn }) {
  // Initializing States
  let [products, setProducts] = useState({
    searching: false,
    docs: [],
  });
  let [data, setData] = useState([]);
  let history = useHistory();

  // Getting Query if user try to find by market
  let { search } = useLocation();
  let { market } = queryString.parse(search);

  useEffect(() => {
    // Changing the title of page
    document.title = "PM Pro - Elevate Your Potential";

    // Logging analytics
    analytics.logEvent("Public Page");
  }, []);

  // Handle Market Filter
  const filterMarket = (market) => {
    setProducts({
      searching: true,
      docs: data.filter((item) => item.market === market),
    });
    history.push("?page=1");
  };

  // Render
  return (
    // Main
    <section className="main public">
      {/* Main Heading */}
      <h2>
        Welcome to
        <Link to={loggedIn ? "/dashboard" : "/login"} className="link">
          &nbsp;PM Pro
        </Link>
      </h2>
      <p>Elevate Your Potential</p>

      {/* Main Container */}
      <div className="main-container">
        {/* Main Container Head */}
        <div className="main-container-head">
          <h3>Active Products</h3>

          {/* Search */}
          <Search
            collection="products"
            field="keyword"
            status={true}
            setData={setProducts}
          />
        </div>

        {/* Table */}
        <div className="table-container">
          <table className="main-table">
            {/* Table Head */}
            <thead>
              <tr>
                <th>Keyword</th>
                <th>Market</th>
                <th>Brand</th>
                <th>Sale Limit</th>
                <th>Total Limit</th>
                <th>Product ID</th>
                <th>Image</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {data.map((product, index) => {
                return (
                  <tr className="products-row" key={index}>
                    <td>{product.keyword.substring(0, 40)}</td>
                    <td
                      className="link"
                      onClick={() => filterMarket(product.market)}
                    >
                      {product.market}
                    </td>
                    <td>{product.brand_name.substring(0, 20)}</td>
                    <td>{product.sale_limit}</td>
                    <td>{product.overall_sale_limit}</td>
                    <td>{product.product_no}</td>

                    {/* Image */}
                    <td>
                      <img src={product.pic} alt="Product Pic" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {products.searching ? (
          <Pagination data={products.docs} setData={setData} />
        ) : (
          <PublicPagination setData={setData} market={market} />
        )}
      </div>
    </section>
  );
}

// Export
export default Public;
