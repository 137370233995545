// Init
import axios from 'axios';
import queryString from 'query-string';

// Handle input
const handleInput = (e, setInput) => {
  setInput((prev) => {
    return {
      ...prev,
      [e.target.name]: e.target.value,
    };
  });
};

// Handle Search
const handleSearch = (e, collection, status, input, setData, history, handleReload, category) => {
  e.preventDefault();
  let { market } = queryString.parse(window.location.search);

  // Checking if user searched
  if (input.query) {
    // Our Search API
    if (window.location.pathname === '/public') {
      // If user searching in public page
      axios
        .get(`/public_search?market=${market || ''}&field=${input.field}&query=${input.query}`)
        .then((searchResult) => {
          setData({
            searching: true,
            docs: searchResult.data,
          });

          // Push user to first page
          history.push(`${window.location.pathname}?market=${market || ''}&page=1`);
        })
        .catch((err) => {
          console.log({ ...err });
        });
    } else {
      // If user searching in portal
      axios
        .get(
          `/search?collection=${collection}&field=${input.field}&query=${input.query}&status=${status}`,
          { params: { category } }
        )
        .then((searchResult) => {
          if (collection === 'products') {
            setData((prev) => {
              return {
                ...prev,
                products: {
                  searching: true,
                  docs: searchResult.data,
                },
              };
            });
          } else {
            setData((prev) => {
              return {
                ...prev,
                [collection]: searchResult.data,
              };
            });
          }

          // Push user to first page
          history.push(`${window.location.pathname}?page=1`);
        })
        .catch((err) => {
          // If session expired then redirect lo login page
          if (err.response.status === 401) {
            window.location = '/login';
          }
          console.log({ ...err });
        });
    }

    // If user searched empty
  } else {
    // If user is in public page
    if (window.location.pathname === '/') {
      setData({ searching: false });
      history.push(`${window.location.pathname}?market=${market || ''}&page=1`);

      // If user is in portal
    } else {
      // If it's not searching then we set original data
      handleReload(collection);
      // Push user to first page
      history.push(`${window.location.pathname}?page=1`);
    }
  }
};

// Export
export { handleInput, handleSearch };
