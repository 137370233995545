// Init
import axios from 'axios';

// Authentication Checking Function
function checkAuth(setUser, setLoggedIn, pathname, history) {
  axios
    .get('/auth')
    .then((res) => {
      setUser(res.data);
      setLoggedIn(true);
    })
    .catch(() => {
      setLoggedIn(false);
      if (pathname !== '/' && pathname !== '/signup' && pathname !== '/public') {
        history.push('/login');
      }
    });
}

// export
export default checkAuth;
